import validate from "/var/www/app/www/totdom.com/releases/20241113222527/node_modules/nuxt/dist/pages/runtime/validate.js";
import case_45sensitive_45redirect_45global from "/var/www/app/www/totdom.com/releases/20241113222527/middleware/case-sensitive-redirect.global.ts";
import fix_45scroll_45position_45global from "/var/www/app/www/totdom.com/releases/20241113222527/middleware/fix-scroll-position.global.ts";
import redirect_45trailing_45slash_45global from "/var/www/app/www/totdom.com/releases/20241113222527/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/var/www/app/www/totdom.com/releases/20241113222527/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  case_45sensitive_45redirect_45global,
  fix_45scroll_45position_45global,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/var/www/app/www/totdom.com/releases/20241113222527/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}